import { Checkbox, CheckboxGroup, Flex, Grid, Heading, Text } from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import { navigate, PageProps } from "gatsby"
import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { formatPrice } from "src/components/PriceTag"
import ReserveNavigation from "src/components/ReserveNavigation"
import ReserveTemplate, { ReserveFormInputs } from "src/components/ReserveTemplate"
import useBoat from "src/lib/hooks/useBoat"
import useLocalStorage from "src/lib/hooks/useLocalStorage"

const ReservePreferences = (props: PageProps<{ boatUid: string }>) => {
  const { boatUid } = props.params
  const location = useLocation()
  const offerId = new URLSearchParams(location.search).get("offerId")
  const { boat } = useBoat(boatUid)

  const [reserveFormValues, setReserveFormValues] = useLocalStorage<ReserveFormInputs>("reserveFormValues")

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ReserveFormInputs>({ defaultValues: reserveFormValues })

  if (!reserveFormValues) {
    navigate("/")
    return null
  }

  const onSubmit: SubmitHandler<ReserveFormInputs> = (values) => {
    setReserveFormValues(values)
    navigate(`/boats/${boatUid}/reserve-payment?offerId=${offerId}`)
  }

  return (
    <ReserveTemplate boatUid={boatUid} step={2}>
      <Heading as="h3" variant="h3" size="lg">
        Enter your details
      </Heading>
      <Text mb={[6, 8]} size="sm">
        Add extras from the list below - you can also add these extras at a later time. Please be aware that some extras
        are paid in advance and others are paid directly at check-in. Pricing details can be found in the payment plan
        section.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} rowGap={4} columnGap={6} alignItems="start">
          <Controller
            name="extras"
            control={control}
            render={({ field }) => (
              <CheckboxGroup {...field}>
                {boat?.extras
                  .filter((e) => e.price > 0)
                  .map((e) => (
                    <Flex key={e.id} justifyContent="space-between" alignItems="center" flexWrap="wrap">
                      <Checkbox size="lg" value={e.id} variant="primary">
                        {e.name}
                      </Checkbox>
                      <Text fontSize="lg" color="primary">
                        {formatPrice(e.price, e.currency)}
                      </Text>
                      <Text mr={16} mb={2}>
                        <strong>Paid at</strong>: {e.payableInBase ? "Check-in" : "Booking"}, {e.unit}
                      </Text>
                      <Text>
                        Dolor cupidatat quis minim magna ipsum. Magna mollit nostrud duis consequat culpa et est non et
                        anim dolor laboris.
                      </Text>
                    </Flex>
                  ))}
              </CheckboxGroup>
            )}
          />
        </Grid>
        <ReserveNavigation
          back="< Back: Your Details"
          next="Next: Payment >"
          isSubmitting={isSubmitting}
          timesUp={false}
          mt={6}
        />
      </form>
    </ReserveTemplate>
  )
}

export default ReservePreferences
