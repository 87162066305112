import React from "react"
import { Button, Flex, FlexProps } from "@chakra-ui/react"
import { navigate } from "gatsby"

interface NavigationProps extends FlexProps {
  isSubmitting: boolean
  timesUp: boolean
  next: string
  back: string
}

const ReserveNavigation = ({ back, next, isSubmitting, timesUp, ...props }: NavigationProps) => {
  return (
    <Flex flexDirection={["column", "row"]} justify={["normal", "end"]} {...props}>
      <Button mr={[0, 4]} size="md" fontWeight="normal" variant="secondary" onClick={() => navigate(-1)}>
        {back}
      </Button>
      <Button mt={[4, 0]} disabled={timesUp} fontWeight="normal" size="md" type="submit" isLoading={isSubmitting}>
        {next}
      </Button>
    </Flex>
  )
}

export default ReserveNavigation
